import React, { useEffect } from 'react';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { WithSidebar } from '../WithSidebar/WithSidebar';
import { BookingSummary } from '../BookingSummary';
import { PageTitle } from '../../../common/components/PageTitle';
import { TotalPrice } from './TotalPrice';
import { PaymentPart } from './PaymentPart';
import { Terms } from './Terms';
import { DifferentPassenger } from './DifferentPassenger';
import { Comment } from './Comment';
import priceApi from '../../../api/priceApi';
import { bookingClean } from '../BookingPage/bookingClean';
import { BookingsService } from '../../../api/BookingsService';
import { useHistory } from 'react-router-dom';
import { UserName } from './UserName';
import { UserAddress } from './UserAddress';
import { UserContacts } from './UserContacts';
import { catchCallback } from '../../../api/request';
import { findLang } from '../../../common/models/lang';
import { Luggage } from './Luggage';
import { Services } from './Services';
import { ChildSeats } from './ChildSeats';
import { CreateAccount } from './CreateAccount';
import { TRANSFER } from '../../models/transfer';
import { ONLINE_PAYMENT_TYPES, PAYMENT_INVOICE } from '../../../constants';
import { checkShortTermBooking } from '../../../common/utils/checkShortTermBooking';
import { Company } from './Company';
import css from './BookingDetailsForm.module.css';
import { EmailSubscription } from './EmailSubscription';
import { ENV } from '../../../env';
import axios from 'axios';
import { InvoiceTo } from './InvoiceTo';

const BookingDetailsForm = ({
    data: { booking, firstTransfer, secondTransfer },
    update: { updateBooking, resetBooking, updateFirstTransfer },
    url,
    dictionary,
    touch: { touchBooking },
    errorsSrc: { bookingErrorSrc },
    validationErrors,
    notifications,
    user,
    setUser,
}) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const onChange = updateBooking;

    const onNumberChange = ({ target, name, value }) => {
        if (target) {
            ({ name, value } = target);
        }
        updateBooking({ value: parseInt(value) || '', name });
    };

    const onCheckboxChange = (e, value) => {
        const { name } = e.target;
        updateBooking({ value, name });
    };

    const onBook = (e) => {
        e.stopPropagation();
        e.preventDefault();

        // matomo conversion tracking (Goal 5 for production, Goal 6 for staging))
        if (window._paq && ENV) {
            if (ENV.ENVIRONMENT_NAME == 'prod') {
                window._paq.push(['trackGoal', 5]);
            } else if (ENV.ENVIRONMENT_NAME == 'staging') {
                window._paq.push(['trackGoal', 6]);
            }
        }

        updateBooking({ name: 'busy', value: true });
        notifications.clearError();
        const bData = {
            ...bookingClean(booking),
            orderDate: DateTime.now().setZone('Europe/Berlin').toISODate(),
            orderTime: DateTime.now().setZone('Europe/Berlin').toFormat('T') + ':00',
            languageId: dictionary.languages.find(({ name }) => name === findLang(i18n.language).name).id,
            autoCalculation: Boolean(booking.price),
        };

        BookingsService.create(bData)
            .then(async (response) => {
                let paymentLink;

                // check if the payment type is "online" and the price has been generated and not short-term
                // if so, generate a payment link
                const isBookingPaidOnline = ONLINE_PAYMENT_TYPES.includes(booking.paymentTypeId);
                const isBookingWithPrice = response.transfers[0].price;
                const isBookingShortTerm = checkShortTermBooking(response.transfers);

                if (isBookingPaidOnline && isBookingWithPrice && !isBookingShortTerm) {
                    const checkoutLinkResponse = await BookingsService.getCheckoutLink(response.bookingNumber);
                    paymentLink = checkoutLinkResponse.paymentLink;
                }

                // some maintenance
                if (user && bData.user.saveToUserProfile) {
                    setUser({
                        ...user,
                        ...bData.user,
                        saveToUserProfile: false,
                        address: {
                            ...user.address,
                            ...bData.user.address,
                        },
                    });
                }

                // if we have a payment link, send the user to the payment page
                if (paymentLink) {
                    history.push({
                        pathname: `${url}/success`,
                        state: { paymentLink },
                    });
                } else {
                    history.push(`${url}/thank-you`);
                    resetBooking();
                }
            })
            .catch((error) => {
                updateBooking({ name: 'busy', value: false });
                notifications.showError(error.response?.data);
            });
    };

    // Method to use for logging
    const extractGPlaceIds = (booking) => {
        const placeIds = [];

        booking.transfers.forEach((transfer) => {
            if (transfer.addressFrom && transfer.addressFrom.gPlaceId) {
                placeIds.push(transfer.addressFrom.gPlaceId);
            }
            if (transfer.addressTo && transfer.addressTo.gPlaceId) {
                placeIds.push(transfer.addressTo.gPlaceId);
            }
        });

        return placeIds;
    };

    const loadPrice = () => {
        const request = priceApi.calculatePrice(bookingClean(booking));

        request
            .then((data) => {
                updateBooking({ name: 'price', value: data });
            })
            .catch(
                catchCallback(() => {
                    updateBooking({ name: 'price', value: null });
                    // if no price could be calculated, log the placeId to check for bugs

                    if (ENV.APP_LOGGER_URL) {
                        const placeIds = extractGPlaceIds(booking);

                        placeIds.forEach((placeId) => {
                            axios({
                                method: 'post',
                                url: ENV.APP_LOGGER_URL,
                                data: { error: 'Could not calculate price for placeId: ' + placeId },
                            }).catch((error) => {
                                console.error('Error while logging calculation 409', error);
                            });
                        });
                    }
                }),
            );

        return () => {
            request.cancel();
        };
    };

    useEffect(loadPrice, [booking.transfers, booking.paymentTypeId]);

    const isValid = !validationErrors;
    const bookingError = bookingErrorSrc.bind(null, validationErrors);
    const isSpecialTransfer = firstTransfer.typeId === TRANSFER.SPECIAL;

    const bottomContent = (className) => (
        <div className={className}>
            <PaymentPart booking={booking} onNumberChange={onNumberChange} dictionary={dictionary} user={user} />
            {/* company fields are needed only for invoice payment */}
            {booking.paymentTypeId === PAYMENT_INVOICE && !!user && (
                <Company
                    booking={booking}
                    bookingError={bookingError}
                    touchBooking={touchBooking}
                    onChange={onChange}
                />
            )}

            <Divider sx={{ my: 2 }} />
            <EmailSubscription booking={booking} onCheckboxChange={onCheckboxChange} />

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 1.5 }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={!isValid || booking.busy}
                    onClick={onBook}
                >
                    {t('booking-action')}
                </Button>
                {booking.busy && <CircularProgress size={24} className={css.buttonProgress} />}
            </Box>
            <Terms />
        </div>
    );

    const content = (
        <form noValidate className={css.host} onSubmit={onBook}>
            <Typography
                variant="h4"
                sx={{
                    mb: 2,
                }}
            >
                {t('billing-address')}
            </Typography>

            <InvoiceTo booking={booking} dictionary={dictionary} onNumberChange={onNumberChange} onChange={onChange} />

            <UserName
                booking={booking}
                dictionary={dictionary}
                onChange={onChange}
                onCheckboxChange={onCheckboxChange}
                onNumberChange={onNumberChange}
                bookingError={bookingError}
                touchBooking={touchBooking}
            />

            <UserAddress
                booking={booking}
                dictionary={dictionary}
                onChange={onChange}
                bookingError={bookingError}
                touchBooking={touchBooking}
            />

            <Divider sx={{ mt: 1.5, mb: 2.5 }} />

            <UserContacts
                booking={booking}
                onChange={onChange}
                bookingError={bookingError}
                touchBooking={touchBooking}
            />

            {user && (
                <div className={css.editUserRow}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={booking.user.saveToUserProfile}
                                onChange={onCheckboxChange}
                                name="user.saveToUserProfile"
                            />
                        }
                        label={<Typography>{t('saveToUserProfile')}</Typography>}
                    />
                </div>
            )}

            <div className={css.expansionsBox}>
                <DifferentPassenger
                    booking={booking}
                    onChange={onChange}
                    bookingError={bookingError}
                    touchBooking={touchBooking}
                />
                {!user && (
                    <CreateAccount
                        booking={booking}
                        onChange={onChange}
                        bookingError={bookingError}
                        touchBooking={touchBooking}
                        onCheckboxChange={onCheckboxChange}
                    />
                )}
                <Luggage
                    luggages={(dictionary && dictionary.luggages) || []}
                    transfer={firstTransfer}
                    updateTransfer={updateFirstTransfer}
                />
                {!isSpecialTransfer && (
                    <Services
                        services={(dictionary && dictionary.services) || []}
                        transfer={firstTransfer}
                        secondTransfer={secondTransfer}
                        updateTransfer={updateFirstTransfer}
                    />
                )}
                {!isSpecialTransfer && (
                    <ChildSeats
                        childSeats={(dictionary && dictionary.childSeats) || []}
                        transfer={firstTransfer}
                        updateTransfer={updateFirstTransfer}
                    />
                )}
                <Comment booking={booking} onChange={onChange} />
            </div>

            {bottomContent(css.bottomBox)}
        </form>
    );

    const sidebar = (
        <>
            <BookingSummary firstTransfer={firstTransfer} secondTransfer={secondTransfer} url={url} />
            <TotalPrice price={booking.price} />
            {bottomContent(css.sidebarBottomBox)}
        </>
    );

    return (
        <>
            <PageTitle>{t('extras-form')}</PageTitle>
            <Box sx={(theme) => theme.mixins.box(theme)}>
                <WithSidebar content={content} sidebar={sidebar} />
            </Box>
        </>
    );
};

export { BookingDetailsForm };
