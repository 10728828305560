import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import css from './BookingDetailsForm.module.css';
import { AlTextField } from '../../../common/components/AlTextField';
import { CUSTOMER_TYPE_COMPANY } from '../../../constants';
import { ErrorOutlineRounded } from '@mui/icons-material';

const InvoiceTo = ({ booking, dictionary, onNumberChange, onChange }) => {
    const { t } = useTranslation();

    const invoiceToOptions = dictionary && dictionary.customerTypes;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    pb: '24px',
                }}
            >
                <Typography variant="h7" sx={{ pr: 2, mb: { xs: 1, sm: 0 } }} noWrap>
                    {t('invoice-to-label')}
                </Typography>

                <div className={css.item}>
                    <RadioGroup
                        name="user.customerTypeId"
                        value={booking.user.customerTypeId}
                        onChange={onNumberChange}
                        row
                    >
                        {invoiceToOptions &&
                            invoiceToOptions.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                                <FormControlLabel
                                    key={item.id}
                                    value={item.id}
                                    control={<Radio />}
                                    label={<Typography>{t(`customerType.${item.name}`)}</Typography>}
                                />
                            ))}
                    </RadioGroup>
                </div>
            </Box>

            {booking.user.customerTypeId === CUSTOMER_TYPE_COMPANY && (
                <>
                    <div className={css.row}>
                        <div className={css.item}>
                            <AlTextField
                                fullWidth
                                autoFocus
                                name="user.companyName"
                                onChange={onChange}
                                value={booking.user.companyName || ''}
                                placeholder={t('placeholder.companyName')}
                                alTooltip={t('tooltip.user.companyName')}
                                required
                            />
                        </div>
                    </div>
                    <div className={css.row}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                            <ErrorOutlineRounded />
                            <Typography
                                variant="caption"
                                sx={{
                                    display: 'block',
                                    lineHeight: 1.4,
                                }}
                            >
                                {t('invoice-correction-info')}
                            </Typography>
                        </Box>
                    </div>
                </>
            )}
        </>
    );
};

export { InvoiceTo };
